<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import { ValidationProvider } from "vee-validate";
import _ from "lodash";
import { constructionKind } from "@/services/constructionKind";

const emits = defineEmits(["update-data"]);

const props = defineProps({
  kindKey: String,
  description: String,
  boundaryTypes: [],
  loadedKinds: [],
  insulationCategories: [],
  editMode: Boolean,
  partData: undefined,
  onlyCategories: Boolean,
});

const selectedData = ref({
  active: false,
  boundaryTypeKey: undefined,
  surface: undefined,
  count: undefined,
  rcValue: undefined,
  rcCategory: undefined,
  uValue: undefined,
  uCategory: undefined,
  gValue: undefined,
  gCategory: undefined,
});

const basicType = computed(() => {
  let typ = "";
  switch (props.kindKey) {
    case constructionKind.glassPrimary:
    case constructionKind.glassSecondary:
      typ = constructionKind.glass;
      break;
    case constructionKind.frontageExclAus:
    case constructionKind.FrontageInclPanels:
      typ = constructionKind.frontage;
      break;
    default:
      typ = props.kindKey;
      break;
  }
  return typ;
});

const hasUvalue = computed(
  () =>
    props.kindKey == constructionKind.door ||
    props.kindKey == constructionKind.panel ||
    props.kindKey == constructionKind.glass ||
    props.kindKey == constructionKind.glassPrimary ||
    props.kindKey == constructionKind.glassSecondary
);
const hasGvalue = computed(() => props.kindKey == constructionKind.glass || props.kindKey == constructionKind.glassPrimary || props.kindKey == constructionKind.glassSecondary);

const filterInsulationCategoriesRc = computed(() => {
  let cat = props.insulationCategories.filter((obj) => {
    return basicType.value === obj.kind && obj.valueType === "Rc";
  });
  cat = addHand(cat);
  return cat;
});
const filterInsulationCategoriesU = computed(() => {
  let cat = props.insulationCategories.filter((obj) => {
    return basicType.value === obj.kind && obj.valueType === "U";
  });
  cat = addHand(cat);
  return cat;
});
const filterInsulationCategoriesG = computed(() => {
  let cat = props.insulationCategories.filter((obj) => {
    return basicType.value === obj.kind && obj.valueType === "G";
  });
  cat = addHand(cat);
  // console.log("ConstructionKindInput, filterInsualtionCategoriesG, cat: ", cat);
  return cat;
});

const rcValueIsEdit = computed(() => selectedData.value.rcCategory === "Hand" && props.editMode);
const uValueIsEdit = computed(() => selectedData.value.uCategory === "Hand" && props.editMode);
const gValueIsEdit = computed(() => selectedData.value.gCategory === "Hand" && props.editMode);

watch(
  () => props.partData,
  () => {
    if (props.partData) {
      selectedData.value.boundaryTypeKey = props.partData.boundaryTypeKey;
      selectedData.value.surface = props.partData.surface;
      selectedData.value.count = props.partData.count;

      if (props.partData.rcValue >= 0) {
        selectedData.value.rcCategory = "Hand";
        selectedData.value.rcValue = props.partData.rcValue;
      } else {
        selectedData.value.rcCategory = determineRcCategory(props.partData.rcValue);
      }
      if (props.partData.uValue >= 0) {
        selectedData.value.uCategory = "Hand";
        selectedData.value.uValue = props.partData.uValue;
      } else {
        selectedData.value.uCategory = determineUCategory(props.partData.uValue);
      }
      if (props.partData.gValue >= 0) {
        selectedData.value.gCategory = "Hand";
        selectedData.value.gValue = props.partData.gValue;
      } else {
        selectedData.value.gCategory = determineGCategory(props.partData.gValue);
      }
      selectedData.value.active = true;
    } else {
      selectedData.value.active = false;
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => selectedData.value.rcCategory,
  () => {
    if (selectedData.value.rcCategory && selectedData.value.rcCategory !== "Hand") {
      selectedData.value.rcValue = determineRcCategoryAverage(selectedData.value.rcCategory);
    } else {
      if (!selectedData.value.rcValue) {
        selectedData.value.rcValue = 0;
      }
    }
  },
  { immediate: false }
);

watch(
  () => selectedData.value.uCategory,
  () => {
    if (selectedData.value.uCategory && selectedData.value.uCategory !== "Hand") {
      selectedData.value.uValue = determineUCategoryAverage(selectedData.value.uCategory);
    } else {
      if (!selectedData.value.uValue) {
        selectedData.value.uValue = 0;
      }
    }
  },
  { immediate: false }
);

watch(
  () => selectedData.value.gCategory,
  () => {
    if (selectedData.value.gCategory && selectedData.value.gCategory !== "Hand") {
      selectedData.value.gValue = determineGCategoryAverage(selectedData.value.gCategory);
    } else {
      if (!selectedData.value.gValue) {
        selectedData.value.gValue = 0;
      }
    }
  },
  { immediate: false }
);

watch(
  () => selectedData.value,
  () => {
    emitData();
  },
  { immediate: false, deep: true }
);

function addHand(cat) {
  cat = _.sortBy(cat, "average");
  cat.unshift({ name: "Hand", description: "Zelf waarde invullen" });
  //      console.log("ConstructionKindInput insulationCategories: ", kindKey, cat);
  return cat;
}

function determineRcCategory(rcVal) {
  const rcCat = filterInsulationCategoriesRc.value.filter((obj) => {
    return obj.lowerBoundary < Math.abs(rcVal) && obj.upperBoundary >= Math.abs(rcVal);
  });
  return rcCat && rcCat.length > 0 ? rcCat[0].name : undefined;
}

function determineUCategory(uVal) {
  const uCat = filterInsulationCategoriesU.value.filter((obj) => {
    return obj.lowerBoundary < Math.abs(uVal) && obj.upperBoundary >= Math.abs(uVal);
  });
  return uCat && uCat.length > 0 ? uCat[0].name : undefined;
}

function determineGCategory(gVal) {
  const gCat = filterInsulationCategoriesG.value.filter((obj) => {
    return obj.lowerBoundary < Math.abs(gVal) && obj.upperBoundary >= Math.abs(gVal);
  });
  return gCat && gCat.length > 0 ? gCat[0].name : undefined;
}

function determineRcCategoryAverage(val) {
  if (val == "Hand") {
    return undefined;
  } else {
    const rcCat = filterInsulationCategoriesRc.value.filter((obj) => {
      return obj.name == val;
    });
    return rcCat && rcCat.length > 0 ? rcCat[0].average : undefined;
  }
}

function determineUCategoryAverage(val) {
  if (val == "Hand") {
    return undefined;
  } else {
    const rcCat = filterInsulationCategoriesU.value.filter((obj) => {
      return obj.name == val;
    });
    return rcCat && rcCat.length > 0 ? rcCat[0].average : undefined;
  }
}

function determineGCategoryAverage(val) {
  if (val == "Hand") {
    return undefined;
  } else {
    const rcCat = filterInsulationCategoriesG.value.filter((obj) => {
      return obj.name == val;
    });
    return rcCat && rcCat.length > 0 ? rcCat[0].average : undefined;
  }
}

function emitData() {
  let data = { kindKey: props.kindKey };
  if (selectedData.value.active) {
    data.active = true;
    data.boundaryTypeKey = selectedData.value.boundaryTypeKey;
    data.surface = selectedData.value.surface;
    data.count = selectedData.value.count;

    data.rcCategory = selectedData.value.rcCategory;
    data.rcValue = selectedData.value.rcValue != undefined ? selectedData.value.rcValue * (selectedData.value.rcCategory !== "Hand" ? -1 : 1) : undefined;

    data.uCategory = selectedData.value.uCategory;
    data.uValue = selectedData.value.uValue != undefined ? selectedData.value.uValue * (selectedData.value.uCategory !== "Hand" ? -1 : 1) : undefined;

    data.gCategory = selectedData.value.gCategory;
    data.gValue = selectedData.value.gValue != undefined ? selectedData.value.gValue * (selectedData.value.gCategory !== "Hand" ? -1 : 1) : undefined;
  } else {
    data.active = false;
  }
  emits("update-data", data);
}
</script>

<template>
  <v-container fluid pa-0>
    <v-row>
      <v-col cols="2">
        <v-switch dense hide-details class="mt-0" :label="description" :readonly="!props.editMode" v-model="selectedData.active">actief</v-switch>
      </v-col>
      <v-col v-if="!onlyCategories">
        <v-container v-if="selectedData.active" fluid>
          <v-row>
            <v-col
              ><ValidationProvider name="Grenst aan" rules="required" v-slot="{ errors }" :vid="`grenstAan-${kindKey}`">
                <v-select
                  label="Grenst aan"
                  hide-details="auto"
                  :items="boundaryTypes"
                  item-text="description"
                  item-value="key"
                  persistent-placeholder
                  dense
                  v-model="selectedData.boundaryTypeKey"
                  :error-messages="errors"
                  :outlined="props.editMode"
                  :class="{ noBorders: !props.editMode }"
                  :readonly="!props.editMode"
                ></v-select></ValidationProvider
            ></v-col>
          </v-row>
          <v-row>
            <v-col
              ><ValidationProvider name="Oppervlakte" rules="decimal:4|between:0,99999.9999" v-slot="{ errors }">
                <v-text-field
                  label="Oppervlakte"
                  ref="surface"
                  hide-details="auto"
                  type="number"
                  step="0.01"
                  persistent-placeholder
                  dense
                  :value="selectedData.surface != undefined ? Math.abs(selectedData.surface) : undefined"
                  @input="selectedData.surface = $event !== '' ? $event : undefined"
                  :error-messages="errors"
                  :outlined="props.editMode"
                  :class="{ noBorders: !props.editMode }"
                  :readonly="!props.editMode"
                ></v-text-field> </ValidationProvider
            ></v-col>
          </v-row>
          <v-row>
            <v-col
              ><ValidationProvider name="Aantal" rules="decimal:2|between:0,99999.99" v-slot="{ errors }">
                <v-text-field
                  label="Aantal"
                  ref="count"
                  hide-details="auto"
                  type="number"
                  step="1"
                  persistent-placeholder
                  dense
                  :value="selectedData.count != undefined ? Math.abs(selectedData.count) : undefined"
                  @input="selectedData.count = $event !== '' ? $event : undefined"
                  :error-messages="errors"
                  :outlined="props.editMode"
                  :class="{ noBorders: !props.editMode }"
                  :readonly="!props.editMode"
                ></v-text-field> </ValidationProvider
            ></v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="6">
        <v-container v-if="selectedData.active" fluid>
          <v-row v-if="!hasUvalue" class="category-border">
            <v-col>
              <v-select
                label="Rc categorie"
                hide-details="auto"
                :items="filterInsulationCategoriesRc"
                item-text="description"
                item-value="name"
                persistent-placeholder
                dense
                v-model="selectedData.rcCategory"
                :outlined="props.editMode"
                :class="{ noBorders: !props.editMode }"
                :readonly="!props.editMode"
              ></v-select>
              <!-- v-on:change="setRcValue" -->
            </v-col>
            <v-col>
              <ValidationProvider name="Rc-waarde" rules="decimal:4|between:0,99999.9999" v-slot="{ errors }">
                <v-text-field
                  label="Rc-waarde"
                  ref="rcValue"
                  hide-details="auto"
                  type="number"
                  step="0.01"
                  persistent-placeholder
                  dense
                  v-model="selectedData.rcValue"
                  :error-messages="errors"
                  :outlined="rcValueIsEdit"
                  :readonly="!rcValueIsEdit"
                  :class="{ noBorders: !rcValueIsEdit }"
                ></v-text-field> </ValidationProvider
            ></v-col>
          </v-row>
          <v-row v-if="hasUvalue" class="category-border">
            <v-col>
              <v-select
                label="U categorie"
                hide-details="auto"
                :items="filterInsulationCategoriesU"
                item-text="description"
                item-value="name"
                persistent-placeholder
                dense
                v-model="selectedData.uCategory"
                :outlined="props.editMode"
                :class="{ noBorders: !props.editMode }"
                :readonly="!props.editMode"
              ></v-select>
            </v-col>
            <v-col>
              <ValidationProvider name="U-waarde" rules="decimal:4|between:0,20.0" v-slot="{ errors }">
                <v-text-field
                  label="U-waarde"
                  ref="uValue"
                  hide-details="auto"
                  type="number"
                  step="0.01"
                  persistent-placeholder
                  dense
                  v-model="selectedData.uValue"
                  :error-messages="errors"
                  :outlined="uValueIsEdit"
                  :readonly="!uValueIsEdit"
                  :class="{ noBorders: !uValueIsEdit }"
                ></v-text-field> </ValidationProvider
            ></v-col>
          </v-row>
          <v-row v-if="hasGvalue" class="category-border">
            <v-col>
              <v-select
                label="G categorie"
                hide-details="auto"
                :items="filterInsulationCategoriesG"
                item-text="description"
                item-value="name"
                persistent-placeholder
                dense
                v-model="selectedData.gCategory"
                :outlined="props.editMode"
                :class="{ noBorders: !props.editMode }"
                :readonly="!props.editMode"
              ></v-select>
            </v-col>
            <v-col>
              <ValidationProvider name="G-waarde" rules="decimal:4|between:0,1.0" v-slot="{ errors }">
                <v-text-field
                  label="G-waarde"
                  ref="gValue"
                  hide-details="auto"
                  type="number"
                  step="0.01"
                  persistent-placeholder
                  dense
                  v-model="selectedData.gValue"
                  :error-messages="errors"
                  :outlined="gValueIsEdit"
                  :readonly="!gValueIsEdit"
                  :class="{ noBorders: !gValueIsEdit }"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
