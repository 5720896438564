const constructionKind = {
  frontage: "Frontage",
  frontageExclAus: "FrontageExclAus",
  frontageInclPanels: "FrontageInclPanels",
  roof: "Roof",
  panel: "Panel",
  floor: "Floor",
  door: "Door",
  glass: "Glass",
  glassPrimary: "GlassPrimary",
  glassSecondary: "GlassSecondary"
};

export { constructionKind };
